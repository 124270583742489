import React, {useEffect, useState} from "react";
import { routlist } from "./routes";
import {Switch, BrowserRouter as Router, Route} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loader from "./components/Loading/Loader";
import {compose} from "redux";
import {connect} from "react-redux";
import {loadMe} from "./store/Auth/action";
import ScrollToTop from "./components/Scroll/ScrollToTop";
import {loadHome} from "./store/Home/action";
import Index from "./pages/Sub";
import {RouteMiddleware} from "./middleware/route.middleware";
import {CLIENT, HOSTER, loadCurrency, CLIENT_LIST} from "./helpers/utils";
import {Toaster} from "sonner";
import logo from "./assets/favicon.ico";


function App({loadMe,auth,loadHome}) {

    const [loading,setLoading] = useState(true)
    const [subdomain,setDomain] = useState(null)

    useEffect(() => {
        var link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
            document.head.appendChild(link);
        }
        link.href = logo
        link.sizes= "160*16"
    }, []);

    useEffect(()=>{
        if (window.location.hash === '#_=_') window.location.hash = '';
        loadHome()
        loadCurrency()
    },[])

    useEffect(() => {
        if (!auth.appLoaded && !auth.isLoading && auth.token && !auth.isAuthenticated) {
            loadMe();
        }
    }, [auth,loadMe]);

    useEffect(() => {
        localStorage.setItem('currency',"XOF")
    }, [window.location]);

    useEffect(()=>{

        const host = window.location.host;
        const subdomains = host.split('.');


        if (!CLIENT_LIST.includes(host)) {
                setDomain({
                    type: 'domain',
                    content: host,
                });
             if (subdomains.length >= 2) {
                let arr;
                if (subdomains.some(subdomain => HOSTER.includes(subdomain))) {
                    arr = subdomains.slice(0, -1);
                } else {
                    arr = []
                }

                if (arr.length > 0) {
                    setDomain({
                        type: 'subdomain',
                        content: arr[0],
                    });
                }else{
                    setDomain(null);
                }
            }
        }


    },[])

    setTimeout(()=>{
        setLoading(false)
    },1000)

    useEffect(() => {
        if(window.location.origin !== "http://kiiako.localhost"){
            const handleKeyPress = (e) => {
                if (e.key === 'F12') {
                    e.preventDefault();
                    console.log('F12 key is disabled.');
                }
            };

            const handleContextMenu = (event) => {
                event.preventDefault();
            };

            document.addEventListener('contextmenu', handleContextMenu);

            window.addEventListener('keydown', handleKeyPress);

            return () => {
                window.removeEventListener('keydown', handleKeyPress);
                document.removeEventListener('contextmenu', handleContextMenu);
            };
        }
    }, []);



  return (


                   <>

                       {
                           subdomain ?
                               <Index subdomain={subdomain} />
                               :
                               <>
                                       {
                                           loading ?
                                               <Loader />
                                               :
                                   <Router>
                                       <ScrollToTop history={Router.history}  />
                                       <Switch>
                                           {routlist.map((route, idx) => {
                                               if(route.isLogged){
                                                   return <RouteMiddleware  exact key={idx} path={route.path} component={route.component} isAuthenticated={auth.isAuthenticated} />
                                               }else{

                                                   return <Route exact  key={idx} path={route.path} component={route.component} />
                                               }
                                           })}

                                       </Switch>
                                   </Router>
                                       }
                               </>
                       }

                       <ToastContainer
                           position="top-center"
                           autoClose={5000}
                           hideProgressBar
                           newestOnTop={false}
                           closeOnClick
                           rtl={false}
                           pauseOnFocusLoss
                           draggable
                           pauseOnHover
                           theme="colored"
                       />

                       <Toaster
                          position="bottom-left"
                          duration={5000}
                          richColors={true}
                          expand={true}
                          toastOptions={{
                              style: {
                                  height : "80px",
                                  textAlign : "left",
                                  float : "left"
                              },
                              className: 'class',
                          }}
                       />
                   </>

  );

}
const mapStateToProps = (state) => ({
    auth: state.loginShop,
    home : state.home
});

export default compose(connect(mapStateToProps, { loadMe,loadHome }))(App);
